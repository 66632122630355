@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap");

img {
  max-width: 100%;
}

ul {
  list-style: none;
  padding: 0;
}
li {
  list-style: none;
}
.checkbox-row {
  display: flex;
}
.checkbox-row div:first-child {
  margin-right: 10px;
}

.btn,
button {
  min-width: 100px;
}
/* *********************** */

.pl-100 {
  padding-left: 100px;
}
.pr-100 {
  padding-right: 100px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pl-50 {
  padding-left: 50px;
}
.pr-50 {
  padding-right: 50px;
}
.pt-50 {
  padding-top: 50px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-40 {
  padding-bottom: 40px;
}
.fs-35 {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 20px;
}
.fs-30 {
  font-size: 30px;
  font-weight: 700;
}
.fs-20 {
  font-size: 20px;
}
.fs-18 {
  font-size: 18px;
}
.fs-15 {
  font-size: 15px;
  font-weight: 400;
}

.mb-50 {
  margin-bottom: 50px;
}
.brd-shadow {
  border: 1px solid #78f6c7;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(120, 246, 199, 0.68);
  -moz-box-shadow: 0px 0px 5px 0px rgba(120, 246, 199, 0.68);
  box-shadow: 0px 0px 5px 0px rgba(120, 246, 199, 0.68);
}
.brd-shadow-danger {
  border: 1px solid #ff0000 !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(222, 22, 22, 0.68);
  -moz-box-shadow: 0px 0px 5px 0px rgba(222, 22, 22, 0.68);
  box-shadow: 0px 0px 5px 0px rgba(222, 22, 22, 0.68);
}
.btn,
.b-radius {
  border-radius: 10px;
}

.sso-profile-wrap {
  font-family: "Poppins", sans-serif;
}

/* ********** LeftCol css start ******** */

.sso-profile-wrap .sso-leftCol {
  padding: 50px;
  width: 382px;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  overflow: auto;
}
.sso-leftCol .profile-wrap {
  /* min-width: 282px; */
}
.sso-leftCol .profilePic {
  width: 150px;
  height: 150px;
  position: relative;
}
.sso-leftCol .profilePic .profilePic-inner {
  border: 1px solid #78f6c7;
  border-radius: 50%;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.sso-leftCol .profilePic img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.sso-leftCol .profilePic .imageUpload {
  position: absolute;
  bottom: 7px;
  right: 7px;
  display: inline-block;
  background-color: #fff;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #c7c2c2;
  color: #6c757d;
}
.sso-leftCol .profileEdit {
  top: -20px;
  right: 10px;
  width: 30px;
  height: 30px;
}
.sso-leftCol .profileEdit .btn_edit {
  background: #6c757d;
  border: none;
  padding: 0;
  min-width: inherit;
  height: 28px;
  width: 28px;
  color: #fff;
  border-radius: 4px;
}
.sso-leftCol .profileEdit .btn_edit .material-icons {
  margin-top: 5px;
  font-size: 17px;
}
.sso-leftCol .profilePic-change {
  top: 10px;
  right: 65px;
  width: 30px;
  height: 30px;
}

.profile-info-form .form-group {
  padding-bottom: 20px;
  position: relative;
  margin: 0;
}
.profile-info-form .form-group label {
  padding-bottom: 10px;
  margin: 0;
}
.profile-info-form .form-group .form-control {
  text-align: center;
}
.profile-info-form .form-group .form-control:focus {
  border: 1px solid #78f6c7;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(120, 246, 199, 0.68);
  -moz-box-shadow: 0px 0px 5px 0px rgba(120, 246, 199, 0.68);
  box-shadow: 0px 0px 5px 0px rgba(120, 246, 199, 0.68);
}
.profile-info-form .form-group input.form-control:-internal-autofill-selected {
  background-color: none !important;
  border: 1px solid #78f6c7 !important;
}
.profile-info-form .btn-discard {
  color: #616161;
  border: 1px solid #616161;
  margin-right: 10px;
}
.profile-info-form .btn-discard:hover {
  border: 1px solid #616161;
}

.profile-info-form .form-group .error-text {
  color: #ff0000;
  font-size: 12px;
  position: absolute;
}

.profileWrap-mobile .profile-info-form {
  padding-top: 0 !important;
}

/* ********** LeftCol css end ******** */

/* ********** ContentCol css start ******** */

.sso-profile-wrap .sso-contentCol {
  margin-left: 380px;
  padding: 50px 0;
}
.sso-contentCol .sso-contentcol-inner {
  max-width: 830px;
}
.sso-contentCol .productWrap .titleBox {
  display: inline-block;
  padding: 15px;
  position: relative;
}
.sso-contentCol .productWrap .titleBox .service {
  background: #048204;
  border-radius: 6px;
  padding: 3px 15px;
  color: #fff;
  top: -15px;
  right: -25px;
  font-size: 13px;
  font-weight: 500;
  -webkit-box-shadow: 0px 7px 12px -6px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0px 7px 12px -6px rgba(0, 0, 0, 0.58);
  box-shadow: 0px 7px 12px -6px rgba(0, 0, 0, 0.58);
}
.sso-contentCol .productWrap .titleBox .premium {
  background: #1d0dbc;
}
.sso-contentCol .productWrap .titleBox .premium a {
  color: #fff !important;
  text-decoration: none !important;
}
.sso-contentCol .productWrap .titleBox .ball-bg {
  background: url(../images/ball-bg.png) no-repeat left bottom;
  width: 33px;
  height: 80px;
  left: 43%;
  bottom: -70px;
}
.sso-contentCol .productWrap .itemWrap {
  padding-top: 20px;
}
.sso-contentCol .productWrap .itemWrap ul {
  padding-left: 0;
}
.sso-contentCol .productWrap .itemWrap ul li {
  padding-bottom: 8px;
  font-size: 20px;
}
.sso-contentCol .productWrap .itemWrap ul li span {
  background: url(../images/li-bg.png) no-repeat -7px 2px;
  padding-left: 45px;
}
.sso-contentCol .productWrap .btn-request {
  -webkit-box-shadow: -1px 7px 14px -5px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: -1px 7px 14px -5px rgba(0, 0, 0, 0.54);
  box-shadow: -1px 7px 14px -5px rgba(0, 0, 0, 0.54);
  border-radius: 8px;
  color: #fff !important;
}

/* ********** ContentCol css end ******** */

.sso_header {
  -webkit-box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 20%);
  background: #fff;
  transition: all 0.5s;
  padding: 10px 30px;
}
.sso_navbar .btn {
  background: none;
  border: none;
  box-shadow: none;
  color: #212529;
  min-width: inherit;
  font-size: 18px;
  padding: 20px;
}
.sso_navbar .btn:focus,
.sso_navbar .btn:hover {
  background: none !important;
  color: #397b3d !important;
}
.sso_header .logo img {
  max-width: 65px;
}
.sso_header .btn_back {
  float: right;
  background: no-repeat;
  border: none;
  box-shadow: none;
  width: 30px;
  height: 30px;
  min-width: inherit;
  margin-top: 15px;
}
.sso_header .btn_back:focus,
.sso_header .btn_back:hover {
  background: none !important;
}
.sso_header .btn_back .material-icons {
  color: ##E1734;
  font-size: 30px;
}
.sso_header .btn_back:hover .material-icons {
  color: #194802 !important;
}

.sso-leftCol .person_man_btn {
  background: #6c757d;
  border: none;
  padding: 0;
  position: absolute;
  right: -25px;
  top: -19px;
  min-width: inherit;
  height: 28px;
  width: 28px;
  color: #fff;
  border-radius: 4px;
}
.sso-leftCol .person_man_btn:hover {
  background: #048204;
}
.sso-leftCol .person_man_btn .material-icons {
  margin-top: 3px;
  font-size: 22px;
}
.management_wrapper {
  padding-top: 30px;
}
.management_wrapper h3 {
  text-align: center;
  margin-bottom: 50px !important;
}
.management_table_wrap .table {
  border-radius: 10px;
  overflow: hidden;
}
.management_table_wrap thead {
  background: #397b3d;
  color: #ffffff;
}
.management_table_wrap .table > :not(:first-child) {
  border-top: none;
}
.management_table_wrap .btn_edit {
  min-width: inherit;
  height: 28px;
  width: 28px;
  background: none;
  border: none;
  color: #397b3d;
  box-shadow: none;
}
.management_table_wrap .btn_edit:focus,
.management_table_wrap .btn_edit:hover {
  background: none !important;
  color: #397b3d;
}
