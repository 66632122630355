@media only screen and (min-width: 1400px) {}

@media only screen and (min-device-width: 1024) and (max-device-width: 1399px) {

}

@media only screen and (min-device-width: 993px) and (max-device-width: 1199px) {
    .sso-contentCol .sso-contentcol-inner {
        max-width: 650px;
    }
   
    .sso-profile-wrap .sso-leftCol {
        padding-left: 28px;
        padding-right: 28px;
    }
}


@media only screen and (max-width: 992px) {
    .sso-leftCol .profile-wrap {
        /* min-width: 255px; */
    }

    .sso-profile-wrap .sso-leftCol {
        width: 320px;
        padding-left: 28px;
        padding-right: 28px;
    }
    .sso-profile-wrap .sso-contentCol {
        margin-left: 318px;
    }
    .sso-contentCol .sso-contentcol-inner {
        max-width: 500px;
    }
    .sso-contentCol .productWrap .titleBox .service {
        right: 0;
    }
    .productWrap h2 {
        font-size: 30px;
    }
    .sso-contentCol .productWrap .titleBox h3 {
        font-size: 22px;
    }
    .sso-contentCol .productWrap .itemWrap ul li {
        font-size: 16px;
    }
    .sso-contentCol .productWrap .itemWrap ul li span {
        background: url(../images/li-bg.png) no-repeat left top;
        padding-left: 35px;
    }
}

@media only screen and (max-width: 767px) {
    .sso-profile-wrap .sso-leftCol {
        width: 100%;
        position: inherit !important;
        box-shadow: none !important;
        height: auto;
        padding-bottom: 25px;
    }
    .sso-leftCol .profileWrap-mobile  {
        max-width: 282px;
    }
    .sso-profile-wrap .sso-contentCol {
        margin-left: 0;
        padding: 0px 20px 0;
    }
    .sso-contentCol .sso-contentcol-inner {
        max-width: 100%;
    }
    .productWrap h2 {
        font-size: 30px;
    }
    .sso-contentCol .productWrap {
        padding-bottom: 50px;
    }
    .sso-contentCol .productWrap .titleWrap {
        text-align: center;
    }
    .sso-contentCol .productWrap .titleBox {
        min-width: 300px;
    }
    .sso-contentCol .productWrap .titleBox h3 {
        font-size: 22px;
    }
    .sso-contentCol .productWrap .titleBox .ball-bg {
        left: 48%;
    }
    .sso-contentCol .productWrap .product-itemWrap:first-child {
        padding-bottom: 30px;
    }
    .sso-contentCol .productWrap .btn-request {
        margin-bottom: 30px;
        font-size: 14px;
    }
    .sso-contentCol .productWrap .itemWrap ul li {
        background: none;
        padding-left: 0;
        text-align: center;
        font-size: 16px;
    }
    .sso-contentCol .productWrap .itemWrap ul li span {
        padding-left: 25px;
    }
}

@media only screen and (max-width: 320px) {
    .sso-contentCol .productWrap .titleBox {
        min-width: 200px;
    }
}


