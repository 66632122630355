/* ******************** Scrollbar style start *********************** */
::-webkit-scrollbar {
    width: 1px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}
/* ******************** Scrollbar style end *********************** */

.authLayout .authLayout-left {
  background-color: #0E1734;
  color: #0E1734;
}
.authLayout .logo-inner {
  text-align: left;
}
.logo-inner h3 {
  color: #ffffff;
  font-size: 28px;
  line-height: 40px;
  font-weight: 600;
}
.signUpPage .signUpForm {
  min-width: 650px;
  padding: 0;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.signUpForm h4 {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
}
.signUpForm h4 a:link,
.signUpForm h4 a:active,
.signUpForm h4 a:visited {
  color: #0E1734;
  margin-left: 10px;
  text-decoration: underline !important;
}
.signUpForm h4 a:hover {
  color: #171f38;
  text-decoration: none !important;
}
.action-wrap .btn {
  font-size: 14px;
  background: #0E1734;
  width: 100%;
}
.action-wrap .btn:hover {
  color: #0E1734;
  border: 1px solid #0E1734;
  background: none;
}
.signUpForm .reg-phoneNumber {
  width: 100%;
  border: none;
  padding: 0;
}

.signUpForm .reg-phoneNumber input {
  width: 100%;
  border: none;
  box-shadow: none;
  border: none;
  height: 40px;
  border: 1px solid #ccc;
}
.signUpForm .reg-phoneNumber input:focus {
  border: 1px solid #0E1734;
}
.signUpForm .reg-phoneNumber .flag-dropdown {
  border: none;
  background: none;
}
.signUpPage {
  padding: 30px 0 0;
}
.signUpForm h3 {
  font-weight: 600;
  margin-bottom: 12px;
  font-size: 1.5rem;
}
.authLayout .logo-wrap {
  max-width: 350px;
  width: 100%;
  background: #0E1734;
  padding: 30px;
  border-radius: 10px;
}
.authLayout .logo-wrap .sm-logo {
  margin: 10px 0 60px;
  max-width: 245px;
}
.authLayout .logo-inner a h4 {
  font-size: 15px;
}
.signUpPage .input-wrap {
  margin-bottom: 15px;
}

.signInPage .signInForm {
  width: 650px;
  padding: 30px 15px;
}

.signInPage .signInForm h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 50px;
  color: #3d3d3d;
}
.signInPage .signup a {
  margin-left: 10px;
  text-decoration: underline !important;
}
.signInPage .signup a:hover {
  text-decoration: none !important;
}
.signInPage .input-wrap {
  margin-bottom: 20px;
}
.signInPage .input-wrap label {
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 10px;
}
.signInPage .btn.btn-primary {
  height: 42px !important;
  padding: 3px 10px;
  width: 100%;
  border: 1px solid #0E1734 !important;
}
.signInForm h5.signup {
  font-size: 14px;
}

.signInForm .input-wrap input {
  height: 50px;
}

.forgot_pass_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.forgot_pass_box input {
  border: 1px solid #d5d4d4 !important;
  padding: 0px 10px;
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.remem-box {
  display: flex;
  align-items: center;
}
.remem-box span {
  color: #d5d4d4;
}
.forgot-pass a {
  color: #d5d4d4 !important;
  text-decoration: underline !important;
}
.forgot-pass a:hover {
  color: #0E1734 !important;
  text-decoration: none !important;
}

.nameField_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nameField_wrap .nameField {
  width: 48%;
}
.signUpPage .form-check-label {
  margin-bottom: 30px;
}

.succMessage {
  background: #3d3d3d;
  display: flex;
  padding: 10px;
  margin-bottom: 30px;
  gap: 10px;
  vertical-align: middle;
  align-items: center;
  color: #ffffff;
}



@media only screen and (max-width: 1380px) {
  .signUpPage {
    padding-top: 10px;
  }
  .signUpPage .input-wrap .userType,
  .signUpPage .input-wrap input {
    height: 35px;
    line-height: 35px;
    font-size: 13px;
  }
  .signUpPage .input-wrap label {
    font-size: 13px;
    margin-bottom: 5px;
  }
  .signUpPage .form-check-label {
    margin: 10px 0;
  }

}
@media only screen and (max-width: 992px) {
  .signUpPage {
    padding: 10px 20px;
  }
  .authLayout .logo-wrap .sm-logo {
    margin: 0px 0 10px;
  }
  .input-wrap label {
    margin-bottom: 10px;
  }
  .signUpForm h4,
  .signUpForm h3 {
    text-align: center;
  }
  .signUpForm h4 {
    font-size: 16px;
  }
  .signUpPage .btn.btn-primary {
    width: 100%;
    margin-top: 10px;
  }
  .signInPage .signInForm {
    width: 100%;
  }
  .signInPage .signInForm h3 {
    font-size: 1.2rem;
  }
  .authLayout .authLayout-left {
    display: none !important;
  }
  .authLayout .authLayout-right {
    height: inherit !important;
  }
  .action-wrap {
    padding: 0 0 10px;
  }

  .signUpPage .signUpForm {
    min-width: inherit;
  }

  .signUpPage .input-wrap {
    margin-bottom: 10px;
  }

}
