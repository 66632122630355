html {
  position: relative;
  min-height: 100%;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* clear fix start */
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .clearfix {
  display: block;
}
img {
  max-width: 100%;
}
* html .clearfix {
  height: 1%;
}
a {
  color: #0E1734 !important;
  text-decoration: none !important;
}
a:hover {
  color: #171f38 !important ;
}
/* Common Css */
.mt-50 {
  margin-top: 50px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-50 {
  margin-bottom: 50px;
}
.fs-base {
  font-size: 16px;
}
.fs-xl {
  font-size: 20px;
}
.h-49 {
  height: 50px !important;
}
.input-wrap ::placeholder {
  opacity: 0.5;
}
.input-wrap .material-icons {
  top: 16px;
  right: 18px;
  z-index: 1;
  color: #0E1734;
  opacity: 0.5;
}
.input-wrap label {
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
}
.input-wrap input {
  border-radius: 4px;
  padding: 0px 10px;
  /* -webkit-box-shadow: 0px 0px 20px -10px rgba(84, 169, 88, 0.64);
  -moz-box-shadow: 0px 0px 20px -10px rgba(84, 169, 88, 0.64);
  box-shadow: 0px 0px 20px -10px rgba(84, 169, 88, 0.64); */
  box-shadow: none;
  height: auto;
  width: 100%;
  border: 1px solid #ccc;
  height: 40px;
  font-size: 14px;
}
.input-wrap input::placeholder,
.input-wrap input:-ms-input-placeholder,
.input-wrap input::-ms-input-placeholder {
  color: #eee !important;
}

.input-wrap select option {
  padding: 5px 0 !important;
}
.input-wrap select option:hover {
  background: #0E1734 !important;
}
.input-wrap .userType {
  border-radius: 7px;
  height: 40px;
  padding-left: 10px;
  background: none;
  /* -webkit-box-shadow: 0px 0px 13px 0px rgba(204, 204, 204, 0.69);
  -moz-box-shadow: 0px 0px 13px 0px rgba(204, 204, 204, 0.69);
  box-shadow: 0px 0px 13px 0px rgba(204, 204, 204, 0.69); */
  box-shadow: none;
  border: 1px solid #ccc;
}
.input-wrap input.phoneNumber {
  padding-left: 110px;
}
.input-wrap .country {
  position: absolute;
  bottom: 1px;
  left: 1px;
  width: 100px;
  height: 46px;
}
.input-wrap .country:focus,
.input-wrap .userType:focus,
.input-wrap input:focus {
  /* -webkit-box-shadow: 0px 0px 20px -10px rgba(84, 169, 88, 0.64);
  -moz-box-shadow: 0px 0px 20px -10px rgba(84, 169, 88, 0.64);
  box-shadow: 0px 0px 20px -10px rgba(84, 169, 88, 0.64); */
  box-shadow: none;
  outline: none;
  border: 1px solid #0E1734;
}
.action-wrap {
  text-align: right;
  padding: 10px 0 20px;
}
.btn.btn-primary {
  background-color: #0E1734;
  border-color: #0E1734;
  -webkit-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.58);
  box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.58);
  min-width: 150px;
  text-align: center;
  border-radius: 8px;
  font-weight: 500;
}
.btn:focus,
.btn-primary:focus,
.btn:hover,
.btn-primary:hover {
  background-color: none !important;
  border-color: #0E1734 !important;
  box-shadow: none !important;
  color: #0E1734;
}
.navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 500;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
  color: #0E1734 !important;
}
.authLayout .alert-danger {
  margin: 5px 0 0;
  padding: 0;
  background: none;
  border: none;
  font-size: 11px;
  color: red;
}
.form-check-label {
  margin-top: 25px;
}
.form-check-label a {
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 992px) {
  .signInPage .leftbar .logo-wrap img {
    max-height: 150px;
  }
  .signUpPage .signUpForm {
    width: 100%;
  }
  .lg-logo {
    display: none;
  }
  .authLayout .logo-wrap a {
    display: block;
  }
  .signInPage .action-wrap,
  .signInPage h4,
  .signInPage h3 {
    text-align: center;
  }
  .signInPage .action-wrap {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .signInPage .leftbar .logo-wrap,
  .signInPage .signRight-innter {
    position: inherit;
  }
  .signInPage .input-wrap .userType,
  .signInPage .input-wrap input {
    padding-left: 20px;
  }
  .signInPage h3 {
    font-size: 25px;
  }
  .signInPage h4 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .signInPage .logo-inner {
    padding: 20px 0 0;
  }
  .signInPage .sign-from-wrap {
    padding: 40px 15px 0;
  }
  .signInPage .input-wrap .userType,
  .signInPage .input-wrap input {
    padding-left: 20px;
  }

  .signInPage .leftbar .logo-wrap img {
    max-height: 90px;
    margin-bottom: 10px;
  }
  .signInPage .leftbar .logo-wrap h4 {
    font-size: 12px;
    margin-bottom: 20px;
  }
}
